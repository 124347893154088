
.ant-tabs-left.cwt-card-tabs,
.ant-tabs-right.cwt-card-tabs {
    margin: -1rem;
}

.ant-tabs-left.cwt-card-tabs > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane, 
.ant-tabs-right.cwt-card-tabs > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane, 
.ant-tabs-left.cwt-card-tabs > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane, 
.ant-tabs-right.cwt-card-tabs > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px;
}

.ant-tabs-left.cwt-card-tabs > .ant-tabs-nav, 
.ant-tabs-right.cwt-card-tabs > .ant-tabs-nav, 
.ant-tabs-left.cwt-card-tabs > div > .ant-tabs-nav, 
.ant-tabs-right.cwt-card-tabs > div > .ant-tabs-nav {
    padding-top: 16px;
    padding-bottom: 16px;
}