@use 'sass:math';
@use 'sass:color';

@import "../../../../node_modules/bootstrap/scss/functions";

$primary: #421fff;
$success: #21eb05;
$secondary: #8c8c8c;
$info: #421fff;
$warning: #edbe54;
$danger: #cd131c;
$light: #d9d9d9;
$dark: #262626;

$font-family-sans-serif: 'Montserrat';
$lead-font-weight: unset;
$font-weight-semibold:        500;
$font-weight-bold:            600;
$font-weight-bolder:          600;

$spacer: 1rem;
$grid-gutter-width: 1rem;
$spacers: (
  0: 0,
  1: math.div($spacer, 3),
  2: math.div($spacer, 1.5),
  3: $spacer,
  4: math.div($spacer, 0.75),
  5: math.div($spacer, 0.6),
);

$display-font-sizes: (
  1: 5rem,
  2: 4rem,
  3: 3.5rem,
  4: 3rem,
  5: 2.5rem,
  6: 2rem
);


@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/maps";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../../../node_modules/bootstrap/scss/root";
@import "../../../../node_modules/bootstrap/scss/reboot";
@import "../../../../node_modules/bootstrap/scss/grid";
@import "../../../../node_modules/bootstrap/scss/type";

// Helpers
@import "../../../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../../../node_modules/bootstrap/scss/utilities/api";

a { text-decoration: none; }