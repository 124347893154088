.ant-card {
    box-shadow: none !important;
}

.ant-card.ant-card-type-inner {
    > .ant-card-head {
        background-color: transparent;
        > .ant-card-head-wrapper {
            > .ant-card-head-title,
            > .ant-card-extra {
                padding: 6px 0;
            }
        }
    }
}

.ant-card.flex-card {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    > *:not(.ant-card-body) {
        flex-shrink: 0;
    }

    > .ant-card-body {
        flex: 1;
        min-height: 0;
    }
}

.ant-card.scroll-card {
    overflow: hidden;
    > .ant-card-body {
        overflow-y: auto;
    }
}

.ant-card {
    > .ant-card-body {
        padding: 1rem;
    }
}

.ant-card.compact-card {
    > .ant-card-body {
        &::after { display: none; }
        &::before { display: none; }
        padding: 0.5rem 0.5rem;
    }
}


.ant-card.no-padding-card {
    > .ant-card-body {
        &::after { display: none; }
        &::before { display: none; }
        padding: 0;
    }
}


.ant-card-hoverable:hover {
    border-color: @border-color-split !important;
    box-shadow: #00000014 0px 4px 16px !important;
}