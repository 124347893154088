.cwt-mailing-modal-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: nowrap;
    pointer-events: none;
}

.cwt-mailing-modal {
    top: 0;
    padding-bottom: 1rem;
    margin-inline-end: 1rem;
    transform-origin: bottom right !important;
    max-width: calc(100vw - 2rem);
    max-height: calc(100vh - 2rem);
    .ant-modal-body {
        padding: 0;
    }
}

.cwt-mailling-modal-overlay {
    pointer-events: none;
}