

.cwt-error-layout-title {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 12px;
}
.cwt-error-layout-message {
    font-size: 24px;
    line-height: 32px;
}