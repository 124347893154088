// Default variables = https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

html { font-size: @font-size-base; }

:root {
    --cwt-media-background: #ffffff;
    --cwt-media-border: 1px solid @border-color-split;
    --cwt-media-border-radius-default: 4px;
    --cwt-media-border-radius-round: 25%;
    --cwt-media-border-radius-circle: 100rem;
    --cwt-media-border-default: 2px;
    --cwt-media-border-outline: 1px solid @primary-color;
    --cwt-media-border-dashed: 1px dashed @border-color-split;
}

.clickable {
    cursor: pointer !important;
}

.draggable {
    cursor: grab !important;
}

.no-click {
    pointer-events: none;
}

.ant-space.ant-space-vertical {
    display: flex;
}
.ant-table-column-title {
    white-space: nowrap;
}

.borderless {
    border: none !important;
}

.shadowless {
    box-shadow: none !important;
}

.ant-form-vertical .ant-form-item-label > label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    display: inline-block;
    vertical-align: middle;
}

.ant-form-item-label > label .ant-form-item-tooltip {
    margin-inline-start: 0.33333rem;
}

.ant-form-vertical .ant-form-item {
    flex-wrap: nowrap;
}

h6.ant-typography,
div.ant-typography-h6,
div.ant-typography-h6 > textarea,
.ant-typography h6 {
    font-size: @heading-6-size;
    font-weight: 500;
    line-height: 1.5;
}

.anticon {
    vertical-align: middle;
}
nz-progress, .ant-progress {
    line-height: 1.25;
}

.ant-spin-nested-loading > div > .ant-spin {
    max-height: unset;
}

.ant-btn .anticon {
    vertical-align: 0.05em;
}

p {
    white-space: pre-wrap;
    tab-size: 4;
    line-height: 1.42;
    word-wrap: break-word;
    margin: 0;
    padding: 0;
}

.ant-radio-group {
    display: flex;
}

.crop-text {
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow-wrap: normal;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--crop-max-rows, 2);
    max-width: var(--crop-max-width, 320px);
}

.bg-body {
    background-color: @body-background;
}

.sticky-bottom { z-index: 900 !important; }

.ant-popover-inner {
    overflow: hidden;
}

.ant-collapse-header {
    min-height: 44px;
    align-items: center !important;
}

.ant-collapse-arrow.anticon-right {
    right: 1.33rem !important;
}