@import "./theme/nz-variables.less";
@import "./theme/nz-components.less";
@import "./theme/nz-customization.less";
@import "./theme/nz-style.less";

.mw-0 { min-width: 0; }

:root {
    --hv-primary: @primary-color;
    --hv-success: @success-color;
    --hv-error: @error-color;
    --hv-warning: @warning-color;
}

.ant-alert-icon {
    align-self: flex-start;
    margin-top: 0.25em;
    margin-right: 1em;
}

.ant-scroll-number-only-unit {
    display: flex;
    align-items: center;
    justify-content: center;
}